import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <h1>Contact at noelamadorluna@gmail.com</h1>
    </div>
  );
}

export default Footer;